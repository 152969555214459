<template>
  <div v-if="currentState === states.LOADING"  class="fxd--center-center">
    <Loader width="2rem" height="2rem" />
  </div>
  <div v-if="currentState === states.READY" class="fll--viewport origin">
    <div class="fll--viewport ovr--scroll-y ovr--scroll-x origin">
      <Artboard :flow="flow.nodes" @ready="handleReady" />
    </div>
    <header class="viewReady-header pad--all-sm abs--top-left abs--top-right"><h1 class="typ--i">{{flow.name}}</h1></header>
  </div>
  <div v-if="currentState === states.ERROR">
    <p>{{errorMessage}}</p>
  </div>
</template>

<script>
import Artboard from "../components/Artboard.vue";
import errors from "../lib/errorMessages.json";
import Loader from '@/components/parts/loader.vue'

export default {
  data: () => ({
    uid: null,
    states: {
      LOADING: 'LOADING',
      ERROR: 'ERROR',
      READY: 'READY'
    },
    stateKey: 'LOADING',
    errorMessage: null
  }),
  components: {Artboard, Loader},
  computed: {
    currentState: {
      get () {
        return this.stateKey in this.states ? this.states[this.stateKey] : null
      },
      set (newState) {
        this.stateKey = newState in this.states ? this.states[newState] : this.stateKey
      }
    },
    flow () {
      return this.$store.getters['flow/flow']
    }
  },
  async mounted () {
    const uid = this.$route.params.uid;
    if (uid) {
      try {
        const flow = await this.$store.dispatch('link/getSharedFlow', uid);
        if (flow.error ) {
          throw flow.message
        } else {
          await this.$store.dispatch('flow/openPublicFlow', {uid, flow});
          this.currentState = this.states.READY;
        }
      } catch (err) {
        this.currentState = this.states.ERROR;
        this.errorMessage = err in errors ? errors[err] : errors.unknown
      }
    } else {
      this.currentState = this.states.ERROR;
    }
  },
  methods: {
    handleReady () {

    }
  }
}
</script>

<style lang="scss" scoped>
.viewReady {
  &-header {
    background: #ffffff;
  }
}
</style>